import React from 'react';
import Seo from "../components/seo";
import Layout from "../components/layout";
import BaseContainer from "../components/common/BaseContainer";
import {Box} from "@mui/material";
import Typography from "@mui/material/Typography"

const playerUrl = "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/";
const playerOptions = "&color=%233c5469&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true";

const soundcloudPlaylists = [
    {title:"POP MIX", src:"1573112941%3Fsecret_token%3Ds-3zYoTK3SI7N"},
    {title:"DANCE & HOUSE MUSIC MIX", src:"1478306503%3Fsecret_token%3Ds-wK8pHkKMNpm"},
    {title:"POP ROCK MIX", src:"1478309047%3Fsecret_token%3Ds-XFarXKGHRFa"},
    {title:"CHILLOUT LOUNGE MIX", src:"1477890226%3Fsecret_token%3Ds-Xualc9gJQS2"},
    {title:"SPORTS MUSIC MIX", src:"1477891480%3Fsecret_token%3Ds-ms1AKvqeT5t"},
    {title:"CHRISTMAS MUSIC MIX", src:"1478305501%3Fsecret_token%3Ds-MlnS3MdaDmD"},
    //{title:"House MIX", src:"1478512606%3Fsecret_token%3Ds-cWGABwbtST2"},  
    {title:"INSTRUMENTAL MUSIC MIX", src:"1478520877%3Fsecret_token%3Ds-zY5FpfUEeIY"}
];



const Muzyka = () => (
  <Layout>
    <Seo title='Muzyka Musicpoint'/>
    <BaseContainer>
      <Box sx={{py: 4}}>
        <Typography variant="h4" component="h1" gutterBottom>
          Muzyka Musicpoint
        </Typography>
        {soundcloudPlaylists.map((playlist, index) => {
            return (              

                <iframe title={playlist.title} width="100%" height="190" scrolling="no" frameborder="no" allow="autoplay" 
                  src={playerUrl + playlist.src + playerOptions}>
                </iframe>
                    
            )
        })}
      </Box>
    </BaseContainer>
  </Layout>
)

export default Muzyka;
